<template>
 <section class="main-login">
    <div class="main-box flexed">
      <div class="login-logo-box">
        <img src="../../assets/images/logo_1080.png" alt="抖工科技">
      </div>
      <Card class="login-card" icon="log-in" :bordered="false">
        <span slot="title">
            找回登录密码
            <p>通过您的手机号重置密码</p>
        </span>
        <div class="form-con">
          <Form ref="forgetForm" :model="forgetForm" :rules="forgetRules">
            <FormItem prop="phone">
              <Input size="large" class="login_input" @on-change="phoneCheck" v-model="forgetForm.phone" placeholder="请输入手机号">
              </Input>
            </FormItem>
            <FormItem prop="code">
              <Row class="flexed">
                <Col span="12">
                  <Input size="large" class="login_input" v-model="forgetForm.code" placeholder="请输入短信验证码"></Input>
                </Col>
                <Col span="1"></Col>
                <Col span="11">
                  <Button
                    v-show="sendAuthCode"
                    size="large"
                    class="button-login-code"
                    :disabled="disabledCode"
                    @click="getCode"
                    type="primary"
                    long>
                    获取验证码
                  </Button>
                  <Button v-show="!sendAuthCode" size="large" class="button-login-code" type="primary" long>{{ codeTime }}</Button>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="password">
              <Row>
                <Col span="24">
                  <Input size="large" type="password"  class="login_input" v-model="forgetForm.password" placeholder="密码由数字和字母组合"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem style="margin-top:40px">
              <Button size="large"
                class="button-login"
                @click="forgetSubmit('forgetForm')" 
                type="primary"
                long>
                提交
              </Button>
            </FormItem>
          </Form>
          <div class="bottom-btn-list">
            <span @click="goLogin">返回登录</span>
          </div>
        </div>
      </Card>
    </div>
  </section>
</template>

<script>
import md5 from "js-md5"
  export default {
    components:{

    },
    data() {
      let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空'));
      } else {
        if(!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}/.test(value))
         {
          // callback(new Error('要求密码最小长度位8位，且必须包含字母、数字和特殊字符（~!@#$%^&*-+/\\.）'));
          callback(new Error('要求密码最小长度位8位，且必须包含字母、数字和特殊字符'));

        }
        callback();
      }
    };
      return {
        sendAuthCode: true,
        // 表单对象
        forgetForm: {},
        // 表单验证
        forgetRules: {
          phone: [{
            type: 'number', required: true, message: '请填写正确的手机号', transform(value) {
              var msg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
              if (!msg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            }
          }],
          code: [
            {required: true, message: '验证码不能为空'},
            {type:"string",min:6,message:"验证码输入错误"},
            {type:"string",max:6,message:"验证码输入错误"},
            ],
          password: [
            // {required: true, message: '密码不能为空'},
            // {type:"string",min:8,message:"密码不少于8位"},
            // {type:"string",max:20,message:"密码不大于20位"},
              { validator: validatePass, trigger: 'blur' }
            ],
        },
        disabledCode: false,
        // 验证码计时器初始值
        codeTime: 0,
      }
    },
    methods: {
      phoneCheck(e) {
        let res = this.common.phoneCheck(e);
        if (!res) {
          this.disabledCode = true;
        } else {
          this.disabledCode = false;
        }
        return res;
      },
      // 获取验证码
      getCode() {
        console.log(111);
        let phone = {...this.forgetForm}
        let time = new Date().getTime()
        let sign = phone.phone.substring(5) + "RESET_PASSWORD" + time + phone.phone.substring(0,5)
        let obj = {phoneNumber:phone.phone,smsCodeType: "RESET_PASSWORD",timestamp:time,sign:md5(sign)  };
        let sendCode = this.common.getStorage("phone");
        if (sendCode) {
          this.$Message.error("已发送验证码");
          return;
        }
        // let obj = {...this.forgetForm,type:3}
        this.common.setStorage("phone", this.forgetForm.phone, 1);
        // this.$axios.post("/sapi/v3/sms", obj, {
        //   "headers": {
        //     "verifyPhone": this.forgetForm.phone
        //   }})
        this.$axios.post(`${this.apiDgtechUrlCros}/dgtech/api/mgmt/oauth/sms/code`, obj)
        // this.$axios.post("/dgtech/api/mgmt/oauth/sms/code", obj)
        .then(res => {
          console.log(res);
          if (res.data.resultStatus) {
            this.sendAuthCode = false;
            this.codeTime = 60;
            var auth_codeTime = setInterval(() => {
              this.codeTime--;
              if (this.codeTime <= 0) {
                this.sendAuthCode = true;
                clearInterval(auth_codeTime);
              }
            }, 1000);
          } else {
            this.$Message.error(res.data.message);
            this.common.delStorage("phone");
          }
        })
          .catch(err => {
            this.$Message.error(err);
            this.common.delStorage("phone");
          })
      },
      // 忘记密码跳转
      forgetSubmit(name) {
        this.$refs[name].validate(valid => {
          if (valid) {
            // this.$axios.post("/sapi/v3/checkSms", {
            //     phone: this.forgetForm.phone,
            //     code: this.forgetForm.code
            //   }
            // ).then(res => {
            //   if (res.data.code == "200") {
            //     this.$axios.post("/sapi/v3/account/password", this.forgetForm).then(res => {

            //       if (res.data.code == "200" || res.data.code == "0" || res.data.code == 200 || res.data.code == 0) {
            //         this.$Message.success('忘记密码成功');
            //         this.$router.push({name: 'login'});
            //       } else {
            //         this.$Message.error(res.data.message);
            //       }
            //     })
            //       .catch(err => {
            //         this.$Message.error(err.message);
            //       })
            //   } else {
            //     this.$Message.error(res.data.message);
            //   }
            // }).catch(err => {
            //   this.$Message.error(err);
            // });
            
            let time = new Date().getTime()
            let sign = this.forgetForm.phone.substring(5) + this.forgetForm.phone + md5(this.forgetForm.password) + this.forgetForm.code + time + this.forgetForm.phone.substring(0,5)
            let obj = {
              phoneNumber: this.forgetForm.phone, 
              password: md5(this.forgetForm.password), 
              verificationCode: this.forgetForm.code, 
              timestamp: time,
              sign: md5(sign)
            }
            this.$axios.post(`${this.apiDgtechUrlCros}/dgtech/api/mgmt/oauth/password/reset`, obj).then(res => {
            // this.$axios.put("/dgtech/api/mgmt/oauth/password/reset", obj).then(res => {

              console.log(res);
              console.log(res.data.resultStatus);
              if (res.data.resultStatus) {
                this.$Message.success('提交成功');
                this.$router.push({name: 'login'});
              } else {
                this.$Message.error(res.data.errorMessage);
              }
            })
          }
        })
      },
      goLogin() {
        this.$router.push({name: 'login'})
      },
      toProtocolPage (type) {
        this.common.toProtocolPage(type);
      },
    },
  }
</script>